import React from 'react';
import Loadable from 'react-loadable';
import { LoadingPage } from '@/components/loading/loadingBase';
import PrivateProvider from '@/components/providers/PrivateProvider';
import HeaderMetadata from '@/layouts/HeaderMetadata';

const CompareLoadable = Loadable({
  loader: () => import('@/sections/Compare'),
  loading: (props) => <LoadingPage isLoading={true} error={props.error} />,
});

const OverviewPage: React.FC<{ id: string }> = ({ id }) => {
  return (
    <React.Fragment>
      <HeaderMetadata title="Overview" />
      <PrivateProvider>
        <CompareLoadable ml_id={id} />
      </PrivateProvider>
    </React.Fragment>
  );
};

export default OverviewPage;
